:root {
  --primary-color: #090909;
  --title-color: #5120d7;
  --secondary-color: #4d4d4d;
  --accent-color: #5053f2;
  --hover-accent: #200863;
  --border-color: #e0e0e0;
  --background-light: #f9f9f9;
  --font-serif: 'Lora', serif;
}

.highlight-gold {
  color: #d4af37; /* Doré */
  font-weight: 600;
}

.highlight-orange {
  color: #ff8c42; /* Orange vif */
  font-weight: 600;
}

.highlight-purple {
  color: #6a1b9a; /* Violet profond */
  font-weight: 600;
}

.bold {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.souligne {
  text-decoration: underline;
}

.article-container {
  max-width: 760px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
  font-family: var(--font-serif);
  color: var(--primary-color);
  line-height: 1.7;
}

.article-header {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--border-color);
}

.article-title {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 1rem 0;
  color: var(--title-color);
  font-family: var(--font-sans);
}

.article-metadata {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-top: 0.5rem;
  text-align: center;
}

.article-content {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

.article-content p {
  margin-bottom: 1.5rem;
  text-align: justify;
  hyphens: auto;
}

.article-content strong {
  font-weight: 600;
  color: var(--primary-color);
}

.article-content em {
  font-style: italic;
  color: var(--secondary-color);
}

.source-links {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
}

.source-links h3 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.source-link {
  margin-bottom: 0.75rem;
}

.source-link a {
  color: var(--accent-color);
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.2s ease;
}

.source-link a:hover {
  color: var(--hover-accent);
}

.original-link {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
  text-align: center;
}

.original-link a {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.original-link a:hover {
  color: var(--hover-accent);
}

@media (max-width: 768px) {
  .article-container {
    padding: 2rem 1rem;
  }

  .article-title {
    font-size: 1.8rem;
  }

  .article-content {
    font-size: 1rem;
  }
}