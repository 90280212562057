@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.74);
    z-index: 1000;
    display: block;
}

.container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    overflow: hidden;
}


.container h1{
    color: #333;
}

.container p{
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.container a{
    color: #3b3385;
    text-decoration: none;
    margin: 15px 0 10px;
}

.container button{
    background-color: #674acf;
    color: #fff;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
    transition: border-color 0.3s ease, color 0.3s ease;
}

.container button:not(.hidden) {
    border: 2px solid transparent;
}

button:hover:not(.hidden)  {
    border-color: gold; 
    color: rgb(255, 252, 237); 
}

.container button.hidden{
    background-color: transparent;
    border-color: #fff; 
}

.container form{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .container form {
        padding: 0 20px; /* Réduit le padding latéral */
    }
}

.container input{
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.form-container{
    position: absolute;
    top: 0;
    transition: all 0.6s ease-in-out;
    height: 100%; 
}

.form-container.inactive {
    opacity: 0;
    visibility: hidden;
}

.form-container input, 
.form-container .password-container input {
  width: 100%; /* Ou toute autre valeur fixe qui convient à votre design */
  box-sizing: border-box; /* Assure que la largeur inclut padding et border */
}

.form-container input{
    background-color: #eee;
}

.form-container .password-container {
  display: flex;
  align-items: center;
}

.form-container .button-toggle-visibility {
  cursor: pointer;
  margin-left: -40px; /* Ajustez selon la taille de votre icône pour ne pas empiéter sur l'espace du texte */
}

.sign-in{
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.active .sign-in{
    transform: translateX(100%);
}

.sign-up{
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    overflow-y: auto;
    text-align: left;
}

/* Conteneur du champ mot de passe */
.password-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%; 
}

/* Bouton pour basculer la visibilité du mot de passe */
.button-toggle-visibility {
    position: absolute;
    right: 8px; 
    top: 55%; /* Centrer verticalement le bouton par rapport au champ mot de passe */
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

/* Images à l'intérieur du bouton */
.button-toggle-visibility img {
    width: 26px; 
    height: auto;
}

.checkbox-container {
    display: flex;
    align-items: center; 
    margin-top: 3%;
    margin-bottom: 4%;
}

form p {
	position: relative;
}

input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label::before {
    font-family: FontAwesome;
    content: '\f096';
    font-size: 1.2em;  
    color: #00B7E8;
    margin-right: 0.3rem  ;
  }
  input[type="checkbox"]:checked + label::before {
    content:'\f14a';
    color: #00B7E8;
  }

.container.active .sign-up{
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move{
    0%, 49.99%{
        opacity: 0;
        z-index: 1;
    }
    50%, 100%{
        opacity: 1;
        z-index: 5;
    }
}

.toggle-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 125px 0 0 90px;
    z-index: 1000;
}

.container.active .toggle-container{
    transform: translateX(-100%);
    border-radius: 0 125px 90px 0;
}

.toggle{
    background-color: #8a40c7;
    height: 100%;
    background: linear-gradient(to right, #5c6bc0, #512da8);
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle h1{
    color: #fff;
}

.container.active .toggle{
    transform: translateX(50%);
}

.toggle-panel{
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
    padding: 0;
}

.toggle-left{
    transform: translateX(-200%);
}

.container.active .toggle-left{
    transform: translateX(0);
}

.toggle-right{
    right: 0;
    transform: translateX(0);
}

.container.active .toggle-right{
    transform: translateX(200%);
}

.toggle-panel p{
    padding-left: 5%;
    padding-right: 5%;
}
.toggle-panel h1{
    padding-left: 3%;
    padding-right: 3%;
}

.recaptcha-container {
    float: right;
    width: 100%;
    transform: scale(1);
    padding-right: 10%;
}
  
@media screen and ((max-width: 670px) or (max-height: 575px)){
    .recaptcha-container,
    #rc-imageselect,
    .g-recaptcha {
        transform:scale(0.84);
        transform-origin:0;
        transform:scale(0.84);
        transform-origin:0 0;
        -webkit-transform:scale(0.84);
        transform:scale(0.84);
        -webkit-transform-origin:0 0;
        transform-origin:0 0;
    }
}

@media screen and (max-width: 565px){
    .recaptcha-container,
    #rc-imageselect,
    .g-recaptcha {
        transform:scale(0.73);
        transform-origin:0;
        transform:scale(0.73);
        transform-origin:0 0;
        -webkit-transform:scale(0.73);
        transform:scale(0.73);
        -webkit-transform-origin:0 0;
        transform-origin:0 0;
    }
}

@media screen and (max-width: 520px){
    .recaptcha-container,
    #rc-imageselect,
    .g-recaptcha {
        transform:scale(0.59);
        transform-origin:0;
        transform:scale(0.59);
        transform-origin:0 0;
        -webkit-transform:scale(0.59);
        transform:scale(0.59);
        -webkit-transform-origin:0 0;
        transform-origin:0 0;
    }
}

.reset-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.212);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* S'assurer qu'il est au-dessus d'autres éléments */
}

.reset-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1051; /* Au-dessus du backdrop */
}

.reset-modal-content input {
    width: 94%;
    background-color: #f0f0f0; /* Gris clair */
    border: 1px solid #f0f0f0; /* Bordure légère */
    border-radius: 4px; /* Coins légèrement arrondis */
    padding: 10px; /* Espace intérieur */
    box-sizing: border-box; /* Pour inclure le padding dans la largeur totale */
}

.reset-modal-content input:hover {
    border-color: #bdcbc9; /* Changer la couleur de la bordure au survol */
}

.error-message {
    color: rgb(115, 47, 7);
}