/* Overlay (arrière-plan semi-transparent) */
.newsletter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Arrière-plan semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background-color 0.5s ease; /* Transition pour la fermeture */
}

/* Modal principale */
.newsletter-modal {
    background: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 90%;
    max-width: 500px;
    position: relative;
    z-index: 1001; /* Plus haut que l'overlay */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Transition pour les animations */
}

.newsletter-modal form {
    margin-top: 20px; /* Espacement réduit pour une meilleure structure */
}

/* Bouton de fermeture */
.newsletter-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease; /* Transition pour l'interaction */
}

.newsletter-close-button:hover {
    color: #ff5c5c; /* Rouge clair au survol */
}

/* Titre */
.newsletter-modal h1 {
    color: rgb(5, 46, 82); /* Vert foncé */
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: bold; /* Accentuation du titre */
}

/* Texte descriptif */
.newsletter-modal p {
    color: rgb(139, 149, 58); /* Vert olive */
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6; /* Meilleure lisibilité */
}

/* Champs de saisie */
.newsletter-input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.newsletter-input:focus {
    border-color: #8000ff; /* Bordure violette */
    box-shadow: 0 0 5px rgba(128, 0, 255, 0.5); /* Glow léger */
    outline: none; /* Supprime le contour par défaut */
}

/* Bouton de soumission */
.newsletter-submit-button {
    background-color: #8000ff;
    border: 2px solid transparent; /* Bordure invisible par défaut */
    color: white;
    padding: 10px 16px; /* Augmentation du padding pour un confort visuel */
    font-size: 1.15rem;
    border-radius: 6px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
}

.newsletter-submit-button:hover {
    background-color: #9a5def; /* Couleur de fond au survol */
    border-color: gold; /* Bordure dorée */
    transform: translateY(-2px); /* Légère remontée */
}

.newsletter-submit-button:active {
    transform: translateY(0); /* Remet le bouton à sa place */
}

/* Messages de succès et d'erreur */
.message {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: bold;
}

.message.success {
    color: rgb(0, 131, 0); /* Vert pour succès */
}

.message.error {
    color: rgb(218, 53, 53); /* Rouge pour erreur */
}

/* Animation de fermeture */
.newsletter-modal.closing {
    opacity: 0;
    transform: scale(0.9);
}

/* Overlay en mode fermeture */
.newsletter-overlay.closing {
    background-color: rgba(0, 0, 0, 0); /* Transition vers transparent */
}
