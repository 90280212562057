.questionnaire {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.questionnaire h2 {
  color: #d9beff;
}

.slider-container {
  margin: 20px 0;
}

.slider-container input[type="range"] {
  width: 100%;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: #a4d0ff;
  font-size: 1.3rem;
}

.feedback {
  margin: 20px 0;
  font-style: italic;
  color: #c8bc0f;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.success-message {
  text-align: center;
  padding: 20px;
  background: #e3f2fd;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.success-message h2 {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 10px;
}

.success-message p {
  font-size: 1.2rem;
  color: #333;
}

.recap {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.recap h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
}

.recap-item {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.recap-item h3 {
  color: #34495e;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.recap-response {
  padding-left: 15px;
  border-left: 3px solid #3498db;
}

.recap-label {
  display: block;
  color: #3498db;
  font-weight: 500;
  margin-bottom: 5px;
}

.recap-feedback {
  color: #7f8c8d;
  margin: 0;
  font-style: italic;
}